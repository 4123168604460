import Repository from './Repository'
import { AxiosResponse } from 'axios';
import {Message} from "@/models/message";
import {MessageWithAttachment} from "@/models/messageWithAttachment";
import Province from "@/models/province.models";
import {ListImage} from "@/models/listImage";
import {RegisterServiceUpdate} from "@/models/registerServiceUpdate";
import {Team} from "@/models/team.models";
import {Employee} from "@/models/employee";
import {EmployeeCreate} from "@/models/employeeCreate";
import {EmployeeUpdate} from "@/models/employeeUpdate";
import {GroupCreate} from "@/models/groupCreate";
import {ReceiverReportCreate} from "@/models/receiverReportCreate";
import {ManagerOfGroupCreate} from "@/models/managerOfGroupCreate";
import {MemberInGroupCreate} from "@/models/memberInGroupCreate";
import {ManagerCheckinCreate} from "@/models/managerCheckinCreate";
import {CarObject} from "@/models/carObject";
import {ManagerBooking} from "@/models/managerBooking";
import {ManagerBookingCreate} from "@/models/managerBookingCreate";
import {ThongKeDiemDanh} from "@/models/thongKeDiemDanh";
import {DoctorExamineCreate} from "@/models/doctorExamineCreate";
import {EmployeeCheckIn} from "@/models/employeeCheckIn";

const resource = '/webhook'

export default new class {
  sendMessage(mess: Message): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/SendMessage`, mess);
  }
  sendMessageWithAttachmentList(mess: MessageWithAttachment): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/SendMessageWithAttachment`, mess);
  }
  getListCustomer(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listCustomer`);
  }
  getProvince(): Promise<AxiosResponse<Province[]>>{
    return Repository.get<Province[]>(`${resource}/listProvince`);
  }
  getListImage(): Promise<AxiosResponse<ListImage[]>>{
    return Repository.get<ListImage[]>(`${resource}/listImage`);
  }
  getListRegister(timeStart: any, timeEnd: any, kind: any): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listRegisterService/${timeStart}/${timeEnd}/${kind}`);
  }
  deleteImageFile(list: ListImage[]): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/deleteImage`, list);
  }
  updateRegisterService(registerServiceUpdate: RegisterServiceUpdate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/updateRegisterService`, registerServiceUpdate);
  }
  registerServiceById(id: number): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/registerService/${id}`);
  }
  createEmployee(employeeCreate: EmployeeCreate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createemployee`, employeeCreate);
  }
  updateEmployee(employeeUpdate: EmployeeUpdate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/updateemployee`, employeeUpdate);
  }
  deleteEmployee(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteemployee/${id}`);
  }
  getListEmployee(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getlistemployee`);
  }
  employeeById(id: number): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getemployeebyid/${id}`);
  }
  getListCustomerWithOutEmployee(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listCustomerWithOutEmployee`);
  }
  createReceiverReport(receiverReportCreate: ReceiverReportCreate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createReceiverReport`, receiverReportCreate);
  }
  deleteReceiverReport(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteReceiverReport/${id}`);
  }
  getListReceiverReport(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listReceiverReport`);
  }
  createMemberInGroup(memberInGroupCreate: MemberInGroupCreate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createMemberInGroup`, memberInGroupCreate);
  }
  deleteMemberInGroup(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteMemberInGroup/${id}`);
  }
  listMemberInGroup(id: number): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listMemberInGroup/${id}`);
  }
  createManagerOfGroup(managerOfGroupCreate: ManagerOfGroupCreate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createManagerOfGroup`, managerOfGroupCreate);
  }
  deleteManagerOfGroup(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteManagerOfGroup/${id}`);
  }
  listManagerOfGroup(id: number): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listManagerOfGroup/${id}`);
  }
  getListDayOff(timeFrom: number, timeTo: number): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listDayOff/${timeFrom}/${timeTo}`);
  }
  createGroup(groupCreate: GroupCreate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createGroup`, groupCreate);
  }
  deleteGroup(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteGroup/${id}`);
  }
  listGroup(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listGroup`);
  }
  addManagerOfCheckin(managerCheckinCreate: ManagerCheckinCreate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createManagerOfCheckin`, managerCheckinCreate);
  }
  getListManagerOfCheckin(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listManagerOfCheckin`);
  }
  deleteManagerOfCheckin(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteManagerOfCheckin/${id}`);
  }
  getListThongKeDiemDanh(dt: number): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listThongKeDiemDanh/${dt}`);
  }
  getListCar(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getListCar`);
  }
  createCar(carObject: CarObject): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createCar`, carObject);
  }
  deleteCar(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteCar/${id}`);
  }
  addManagerOfBooking(managerBookingCreate: ManagerBookingCreate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createManagerOfBooking`, managerBookingCreate);
  }
  getListManagerOfBooking(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listManagerOfBooking`);
  }
  deleteManagerOfBooking(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteManagerOfBooking/${id}`);
  }
  exportListCheckIn(thongKe: ThongKeDiemDanh[]): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/exportCheckInFile`, thongKe);
  }
  getListDoctorExamine(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getListDoctorExamine`);
  }
  createDoctorExamine(doctorExamineCreate: DoctorExamineCreate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createDoctorExamine`, doctorExamineCreate);
  }
  deleteDoctorExamine(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteDoctorExamine/${id}`);
  }
  getListEmployeeInHospital(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getListEmployeeInHospital`);
  }
  getListThongKeDiemDanhMonth(dt: number, id: number): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getCheckInInMonth/${dt}/${id}`);
  }
  getListDepartments(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getListDepartments`);
  }
  createEmployeeInHospital(employeeCheckIn: EmployeeCheckIn): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createEmployeeInHospital`, employeeCheckIn);
  }
  getDanhSachCls(ma: string): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getDanhSach_CLS/${ma}`);
  }
}
