

import moment from 'moment';
import { computed, ref } from 'vue'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {useStore} from "vuex";
import {ListCustomer} from "@/models/listCustomer";
import zaloRepository from "@/services/ZaloRepository";
import {FilterMatchMode} from "primevue/api";
import {ManagerCheckinCreate} from "@/models/managerCheckinCreate";
import {ManagerCheckin} from "@/models/managerCheckin";
import {CarObject} from "@/models/carObject";
import {Ds_Cls} from "@/models/ds_Cls";
import {FileDetail} from "@/models/fileDetail.models";
import axios from "axios";

export default {

  setup(){
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const maYTe = ref("");
    const listCLS = ref([] as Ds_Cls[]);
    const ngaySinh = ref("");

    const loadListCls = () => {
      zaloRepository.getDanhSachCls(maYTe.value)
          .then((response) => {
            listCLS.value = response.data;
            console.log("#####$$$$$$$$$$ listCLS: " + JSON.stringify(listCLS.value));
            console.log("#####$$$$$$$$$$ listCLS.value.length: " + listCLS.value.length);
            if(listCLS.value.length > 0)
            {
              ngaySinh.value = formatDate(listCLS.value[0].ngaysinh);
            }
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:'Lỗi khi tải danh sách xe',
              life: 2000
            });
          });
    }

    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    const formatDateTime = (date) => {
      console.log("@@@@@@@@@################ formatDateTime date: " + date.value);
      if((date == null)||(date == ''))
      {
        return "chưa có thông tin"
      }
      else
        return moment(String(date)).format('HH:mm:ss DD/MM/YYYY');
    };

    const formatDate = (date) => {
      console.log("@@@@@@@@@################ formatDateTime date: " + date.value);
      if((date == null)||(date == ''))
      {
        return "chưa có thông tin"
      }
      else
        return moment(String(date)).format('DD/MM/YYYY');
    };
    const listLink = ref([] as string[]);
    let listFileName = ref([] as FileDetail[]);
    const loadingBar = ref(false);
    const upload = () => {
      {
        // listCLS.value[0].sodienthoai = "0905333122"; // debug
        // listCLS.value[0].sodienthoai = "0905123456"; // debug
        listLink.value = [];
        var files = new FormData();
        listFileName.value.forEach(x =>
        {
          files.append("formFiles", x.value, x.nameFile);
        });
        loadingBar.value = true;
        axios.defaults.headers.common = {'Authorization': `Bearer ${store.state.token}`}
        axios.post(`../api/File/UploadFileCLS/${listCLS.value[0].sodienthoai}`, files,
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
              }
            }).then(response => {

          loadingBar.value = false;
          listFileName.value = [];
          if(response.data.status) {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Gởi kết quả thành công',
              life: 2000
            });
          }
          else{
            toast.add({
              severity: 'warn',
              summary: 'Lỗi',
              detail:response.data.log,
              life: 2500
            });
          }
        })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2500
              })});
      }
    };

    const fileChange = (fileList) => {
      var leng = fileList.length;
      for (var i = 0; i < leng; i++)
      {
        var filetemp = ref({} as FileDetail);
        filetemp.value.nameFile = fileList[i].name;
        filetemp.value.value = fileList[i];
        listFileName.value.push(filetemp.value);
      }
    };

    return {
      error,
      filters,
      clearFilter,
      initFilters,
      maYTe,
      formatDateTime,
      listCLS,
      loadListCls,
      ngaySinh,
      upload,
      listLink,
      listFileName,
      loadingBar,
      fileChange,
    }
  }
}

