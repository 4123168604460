import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sendFile = _resolveComponent("sendFile")!
  const _component_SendMessagePage = _resolveComponent("SendMessagePage")!
  const _component_showListRegisterService = _resolveComponent("showListRegisterService")!
  const _component_LoginPage = _resolveComponent("LoginPage")!

  return ($setup.isLoggedIn)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($setup.clsValue)
          ? (_openBlock(), _createBlock(_component_sendFile, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              ($setup.permissionAdmin)
                ? (_openBlock(), _createBlock(_component_SendMessagePage, { key: 0 }))
                : (_openBlock(), _createBlock(_component_showListRegisterService, { key: 1 }))
            ]))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createVNode(_component_LoginPage)
      ]))
}