
import LoginPage from "@/pages/LoginPage.vue";
import SendMessagePage from "@/pages/SendMessagePage.vue";
import sendFile from "@/pages/SendFile.vue";
import showListRegisterService from "@/pages/ShowListRegisterService.vue";

import { useStore } from 'vuex'
import {computed, ref} from 'vue'
import {useRouter} from "vue-router";

export default {
  components: {
    LoginPage,
    SendMessagePage,
    sendFile,
    showListRegisterService
  },
  setup(){
    const store = useStore();
    const clsValue = ref(false);
    const permissionAdmin = ref(false);
    const isLoggedIn = computed(() => {
      if(store.state.permission == 'cls') {
        clsValue.value = true;
      }
      else {
        clsValue.value = false;
      }
      if((store.state.permission == 'admin'))
      {
        permissionAdmin.value = true;
      }
      else if(store.state.permission == 'user')
      {
        permissionAdmin.value = false;
      }
      return !!store.state.token});

    const router = useRouter();

    console.log("Home ######## isLoggedIn: " + JSON.stringify(isLoggedIn));
    console.log("clsValue: " + JSON.stringify(clsValue.value));
    // router.push({
    //   name: 'sendmessage'
    // });
    return {
      isLoggedIn,
      clsValue,
      permissionAdmin
    }
  }
}
