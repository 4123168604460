

import { computed, ref } from 'vue'
import moment from 'moment';
import {FilterMatchMode, FilterOperator} from "primevue/api";
import router from "@/router";
import {useToast} from "primevue/usetoast";
import {useStore} from "vuex";
import {RegisterService} from "@/models/registerService";
import ZaloRepository from "@/services/ZaloRepository";
import {ListService} from "@/models/listService";
import {useRouter} from "vue-router";

export default {
  setup() {
    const toast = useToast();
    const store = useStore();
    const list = ref([] as RegisterService[]);
    const listTemp = ref([] as RegisterService[]);
    const date = ref();
    const listService = ref([] as ListService[]);
    const service = ref("");
    const router = useRouter();
    const kind = ref(0);

    if(!(!!store.state.token)){
      router.push({
        name: 'home'
      });
    }

    const selectCalendar = () => {
      if((date.value[0] != null)&&(date.value[1] != null))
      {
        listTemp.value = [];
        list.value = [];
        ZaloRepository.getListRegister(date.value[0]/1000, date.value[1]/1000, kind.value)
            .then((response) => {
              listTemp.value = list.value = response.data;
              list.value.forEach(x => {
                const check = ref(false);
                listService.value.forEach(y => {
                  if(y.serviceCode == x.serviceCode)
                    check.value = true;
                });
                if(!check.value)
                {
                  listService.value.push({
                    serviceCode: x.serviceCode,
                    serviceName: x.serviceName
                  });
                }
              });
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2500
              })})
      }
    };

    //selectCalendar();

    const clearCalendar = () => {
      list.value = [];
      listService.value = [];
    }

    const formatDateTime = (date) => {
      if((date == null)||(date == ''))
      {
        return "chưa có thông tin"
      }
      else
        return moment(String(date)).format(' HH:mm DD/MM/YYYY');
    };

    const getFilter = () => {
      if((service.value != null)&&(service.value != ""))
      {
        list.value = listTemp.value.filter(x => x.serviceCode == service.value);
      }
      else{
        list.value = listTemp.value;
      }
    }
    const validDate = (registerService: RegisterService) => {
      return registerService.timeConfirm;
    }

    const clearDate = () => {
      console.log("@@@@@@@@@################ clearDate");
      if(date.value != null)
        date.value = ref();
    }

    return {
      formatDateTime,
      list,
      date,
      selectCalendar,
      clearCalendar,
      listService,
      service,
      getFilter,
      validDate,
      kind,
      clearDate
    }
  }

}
